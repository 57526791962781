import React from "react"
import { graphql } from "gatsby"
import CatNews from "../components/catnews"
import { ArticleConsumer, PlayerContext } from "../components/player-context"

export default function BreakingNewsTemplate({ data }) {
  return (
    <ArticleConsumer>
      {playerContext => (
        <CatNews
          {...playerContext.setPlaylistTitle("عاجل")}
          {...playerContext.setFuturePlaylist(data.nshra.breakingNews.articles)}
          items={data.nshra.breakingNews.articles}
          catArabicName="عاجل"
          hidePlayButton={false}
        ></CatNews>
      )}
    </ArticleConsumer>
  )
}

export const query = graphql`
  query($countryCode: String) {
    nshra {
      breakingNews(countryCode: $countryCode) {
        articles {
          id
          image
          link
          predicted_category
          published
          rank
          source_category
          source_countryCode
          sourcename
          title
          sources_count
        }
      }
    }
  }
`
